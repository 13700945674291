<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">
                Travel Insurance for Over 65s
                </h1>
            </div>
            
            <div class="box b-shdw-3 mt-5">
                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'"/>
                <p class="paragraph-text">
                    Being over 65 doesn’t mean your travelling days are over, for many they’re only just beginning!
                </p>
                <p class="paragraph-text">
                    Travel insurance if you’re over 65 does tend to be a little more expensive, because statistics show that the older we get, the more likely we are to make an insurance claim.
                </p>
                <p class="paragraph-text">
                    However, don’t let expensive insurance put you off going away on a big adventure. Take a look below to find out a little more about travel insurance for over 65s or <a href="get-quote" target="_blank" class="text-link">click here get a quote today</a>.
                </p>
                <div style="display:flex;justify-content:space-between;">
                    <div>
                        <p class="blue-text">Specialist Travel Insurance for Over 65s</p>
                        <p class="paragraph-text">
                            Generally speaking, standard travel insurance policies only cover those aged between 18 and 64. Even if you’re in excellent health, being over 65 still means that you’ll pay a little more for your travel insurance. However, when you use our online quote system, you’ll enter all your information and a quote will be generated for your particular set of circumstances.
                        </p>
                        <p class="paragraph-text">
                            When you opt for specialist senior travel insurance there might be a number of different benefits, including an upper age limit of 99 on our single trip policies. You may also benefit from extending your cover to insure your pre-existing medical conditions.
                        </p>
                        <p class="paragraph-text">
                            Here at Covered2go, we’re proud to offer a range of different policies to suit a wide range of holiday choices. However, do read the terms and conditions closely to make sure that it covers everything you might need. 
                        </p>     
                        <p class="blue-text">Factors That Can Change the Cost of Over 65s Travel Insurance</p> 
                        <p class="paragraph-text">
                            When you find yourself paying higher costs once you hit 65, you might be left wondering why. Below we’ve broken down the different factors that can impact on the cost of your policy.
                        </p>
                    </div>
                    <div style="width:500px; display: flex; flex-direction:column;flex-wrap:wrap;align-items:center;justify-content:flex-start;margin-top:40px;padding:5px;">
                        <img src="/img/defaqto.png" />
                        <div id="trustbox" style="margin-top:30px;" class="trustpilot-widget" data-locale="en-GB" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="50b7a9eb00006400051f090e" data-style-height="40px" data-style-width="100%" data-theme="light">
                            <a href="https://uk.trustpilot.com/review/covered2go.co.uk" target="_blank" rel="noopener">Trustpilot</a>
                        </div>
                        <img src="/img/superior-rated-badge.png" />
                    </div>
                </div>
                <p class="blue-text">Holiday Destination</p>
                <p class="paragraph-text">
                    The further you’re travelling away from the UK, the higher the cost of your policy will be. This is because in locations such as the USA, medical costs are incredibly expensive and can quickly mount up. Though it can be tempting to opt for the cheaper policy, particularly if you’re in great health, sometimes getting the higher level of cover can help you travel more comfortably knowing you have the protection you need.
                </p>
                <p class="blue-text">Length of Your Trip</p>
                <p class="paragraph-text">
                    Similarly, to any form of travel insurance, the longer your trip, the higher your travel insurance will be. This is because the longer you’re there, the higher the risk of an accident or illness occurring. Here at Covered2go we also offer <a href="longstay-travel-insurance" target="_blank" class="text-link">Longstay Travel Insurance</a> that could be more suitable for your needs.
                </p>
                <p class="blue-text">Taking a Cruise</p>
                <p class="paragraph-text">
                    Policies for over 65s travelling by cruise will tend to incur a little extra cost. Here at Covered2go we offer <a href="cruise-travel-insurance" target="_blank" class="text-link">specialist cruise insurance</a>. This will cover you for things unique to cruise travel, such as cabin confinement and missed port departure. 
                </p>
                <p class="blue-text">Pre-existing medical conditions</p>
                <p class="paragraph-text">
                   Of course, <a href="pre-existing-medical-conditions" target="_blank" class="text-link">pre-existing medical conditions</a> will have an impact on your policy. As part of our quote process you’ll be taken through to our bespoke online medical screening, which will determine the full cover and additional premium. It’s really important to declare each condition to ensure you have the level of cover that you need.     
                </p>
                <p class="blue-text">Sports Activities or Winter Sports</p>
                <p class="paragraph-text">
                    Whatever <a href="sports-activities-cover" target="_blank" class="text-link">sporting activities</a> you have planned during your trip, whether it’s skiing or golf, make sure you have protection under your policy. It not only keeps you safe, but also protects any specialist equipment you might be travelling with.
                </p>
                <p class="blue-text underline">FAQs about Travel Insurance for Over 65s</p>
                <p class="paragraph-text">
                    If you want to know more about over 65s travel insurance, then we’ve compiled a number of frequently asked questions below:
                </p>
                <p class="blue-text">Do you have to buy specialist insurance if you’re over 65?</p>
                <p class="paragraph-text">
                    Generally yes, but it depends on the individual insurer. Standard travel insurance policies generally only cover those between 18 and 64. Covered2go single trip policies, have an upper age limit of 99. Our multi-trip policies have an upper age limit of 79.
                </p>
                <p class="blue-text">I have an EHIC card, can I use this if I’m over 65 and travelling in Europe?</p>
                <p class="paragraph-text">
                    People from the UK can still use their EHIC (European Health Insurance Card) for travel as long as it is still in date (check the expiry date). However, if your EHIC has expired, you can <a href="https:\www.nhs.uk\using-the-nhs\healthcare-abroad\apply-for-a-free-uk-global-health-insurance-card-ghic\" target="_blank" class="text-link">apply for a GHIC here</a>. 
                </p>   
                <p class="paragraph-text">
                    It is vital that you still have travel insurance in place, as it only covers public medical expenses, rather than any of the other associated costs such as repatriation.
                </p>                
                <p class="blue-text">Where can I find the best over 65s travel insurance?</p>
                <p class="paragraph-text">
                    Here at Covered2go, our online quote system will look at your individual circumstances before providing a range of policies to choose from. As travel insurance specialists we’re proud to offer a wide-range of insurance policies for the over 65s.
                </p>                
                <p class="blue-text">What isn’t covered by over 65 travel insurance?</p>
                <p class="paragraph-text">
                    You should check your individual policy wording, however there are a number of different things that generally won’t be included, for example:
                    <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom"><strong>Undeclared Medical Conditions</strong> – If you fall ill while on holiday and it’s related to a pre-existing medical condition that wasn’t declared on your policy, then medical expenses wouldn’t be covered. It’s so important to make sure every condition is stated, so you have the peace of mind that you have the level of cover you need.</li>
                            <li class="list-item extra-padding-bottom"><strong>Certain Sporting Activities</strong> – If you’re planning certain sports activities while you’re away then make sure you have the cover you need, or you won’t be protected. Sports and Winter Sports Cover is generally offered as an added extra when you’re shopping around for your policy.</li>
                            <li class="list-item extra-padding-bottom"><strong>Reckless Behaviour</strong> – If an accident occurs and you are found to be under the influence of alcohol, then this could lead your claim to be refused. Similarly if your behaviour is considered reckless and leads to an accident. Speak to our friendly team here at Covered2go for more information.</li>
                    </ul>        
                </p>                
                <p class="blue-text">What is the age limit for travel insurance?</p>
                <p class="paragraph-text">
                    Age restrictions do apply to Covered2go policies:
                      <ul class="main-list-class extra-padding-top">
                            <li class="list-item extra-padding-bottom">Single Trip Policies: Maximum Age 99</li>
                            <li class="list-item extra-padding-bottom">Annual Multi Trip Policies: Maximum Age 79</li>
                            <li class="list-item extra-padding-bottom">Long Stay Policies: Maximum Age 64</li>
                      </ul>
                </p>                
                <p class="blue-text">Who can I speak to about over 65s travel insurance?</p>
                <p class="paragraph-text">
                    Our expert team are always on hand to assist you. Our online quote system is straightforward to use and explains each policy in simple terms. If you do have any questions about your policy, please don’t hesitate to <a href="contact-us" target="_blank" class="text-link">get in touch</a>.
                </p>

                <br />

                <InfoButtonOptions :policyType="'ST'" :coverType="'ST'" />
                <info-boxes />
                <!-- <trustpilot-banner /> -->
            </div>
        </div>
    </transition>
</template>
<script>
    import InfoBoxes from '../components/common/InfoBoxes';
    // import TrustpilotBanner from '@/components/common/trustpilot/TrustpilotBanner';
    import InfoButtonOptions from '@/components/common/InfoButtonOptions';
    export default {
        name: "TravelByDestination",        
        components: {
            InfoBoxes,
            InfoButtonOptions,
        },
        methods: {
            loadTrustpilot() {
                const trustbox = document.getElementById('trustbox');
                window.Trustpilot.loadFromElement(trustbox);
            }
        },
        mounted() {
            this.loadTrustpilot();
        }
    }
</script>

<style lang="scss" scoped>
    .main-page-title {color: $c2g_orange; font-size:130%; text-decoration:none; text-align:left; padding-bottom:15px; font-weight: bold;}
    .paragraph-text {color:black; font-size:100%; padding-bottom:15px;}
    .main-list-class{padding-left:25px; padding-bottom:15px;}
    .list-item{list-style-type: disc; color:black;}
    .blue-text {margin-top: 10px; text-align:left; color: $c2g_blue; font-weight: bold; font-size:115%; padding-bottom:15px;}
    .bold-text{font-weight:bold;}
    .text-link{color:$c2g_orange; text-decoration: underline;}
    .extra-padding-bottom{padding-bottom:10px}
    .extra-padding-top{padding-top:10px}
    .underline{text-decoration:underline}
</style>